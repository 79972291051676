body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pageContentWrapper {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
}

.pageContentHeader {
  padding: 0px 10px 30px 10px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.headerButton {
  height: 42px;
}
.formMultiCols {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formMultiCols > div {
  flex: 1;
}

.previewBox {
  -webkit-box-shadow: 2px 2px 6px 2px #ddd;
  box-shadow: 2px 2px 6px 2px #ddd;
}

.smartGoalLabel {
  flex: 1;
  padding: 10px;
  border: 0px solid #ddd;
  border-radius: 10px;
  margin: 0px 10px 10px 0px;
  min-width: 200px;
}

.smartGoalBlankLabel {
  flex: 1;
  padding: 0px 10px;
  height: 1px;
  margin: 5px 10px 5px 0px;
  min-width: 200px;
}

.smartGoalLabelShort {
  flex: 1;
  padding: 10px;
  border: 0px solid #ddd;
  border-radius: 10px;
  margin: 0px 10px 10px 0px;
  min-width: 130px;
}

.smartGoalBlankLabelShort {
  flex: 1;
  padding: 0px 10px;
  height: 1px;
  margin: 0px 10px 10px 0px;
  min-width: 200px;
}

.smartGoalLabelSelect {
  flex: 1;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;
  margin: 10px 0px;
  min-width: 200px;
  cursor: pointer;
}

.smartGoalLabelSelect:hover {
  border: 2px solid #2462a9;
}

.labelCloseButton {
  color: #999;
  cursor: pointer;
}
.labelCloseButton:hover {
  color: red;
}

.labelAddButton {
  color: #666;
  cursor: pointer;
}
.labelAddButton:hover {
  color: #2462a9;
}

.activeLink {
  color: #000;
  cursor: pointer;
}
.activeLink:hover {
  color: #2462a9;
}

.activeButton {
  background-color: #fff;
  color: #666;
  text-decoration: none;
  cursor: pointer;
}
.activeButton:hover {
  color: #000;
  text-decoration: none;
  background-color: #cadfdf;
}

.activeLinkRed {
  color: #000;
  cursor: pointer;
}
.activeLinkRed:hover {
  color: red;
}

.nonactiveLink {
  color: #999;
  cursor: pointer;
}
.nonactiveLink:hover {
  color: #2462a9;
}

.nutritionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #666;
}

.foodCard {
  border-radius: 20px;
  overflow: hidden;
  margin: 20px;
  position: relative;
  flex: 1;
  min-width: 280px;
}

.foodCardBlank {
  margin: 20px;
  position: relative;
  flex: 1;
  min-width: 280px;
}

.formFooter {
  padding: 50px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-container {
  height: 600px;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
.editPanel {
  flex: 1;
  padding: 20px;
  margin-right: 50px;
  /*border: 1px solid #ddd;*/
  background-color: #f5f5f5;
  border-radius: 10px;
}
.editImagePanel {
  border: 1px solid #ddd;
  padding: 10px 0px 10px 5px;
  border-radius: 10px;
}

.imgZoom {
  transition: transform 0.2s; /* Animation */
}

.resultRow {
  border-radius: 15px;
  padding: 10px 15px;
  margin: 5px -10px;
  border-bottom: 1px solid #ddd;
}

.resultRow:hover {
  background-color: #ddd;
  cursor: pointer;
}

.nutriLbl-enter {
  opacity: 0;
  /* transform: scale(0.1); */
  transform: rotateY(180deg);
}
.nutriLbl-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 600ms;
}
.nutriLbl-exit {
  opacity: 1;
}
.nutriLbl-exit-active {
  opacity: 0;
  /* transform: scale(0.1); */
  transform: rotateY(180deg);
  transition: opacity 300ms, transform 600ms;
}

.fade-enter {
  max-height: 0;
  opacity: 0;
}

.fade-enter-active {
  max-height: 30px;
  opacity: 1;
  transition: all 500ms;
}

.fade-exit {
  max-height: 30px;
  opacity: 1;
}

.fade-exit-active {
  max-height: 0;
  opacity: 0;
  transition: all 500ms;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.scroll-hide {
  border: 2px solid #f3f3f3;
  padding-left: 16px;
  overflow: auto;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
}

/* this will hide the scrollbar in webkit based browsers - safari, chrome, etc */
.scroll-hide::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.TextField-without-border-radius fieldset {
  border-radius: 20px;
}

.input-chat:focus {
  outline: none;
}
/* .MuiFormHelperText-contained {
  margin-left: 0px !important;
  margin-right: 14px;
} */

.big-switch .MuiSwitch-root {
  width: 75px !important;
  height: 42px !important;
  padding: 12px !important;
}
.big-switch .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(30px) !important;
}
.big-switch .MuiIconButton-root {
  padding: 6px !important;
}
.big-switch .MuiSwitch-thumb {
  width: 30px !important;
  height: 30px !important;
}
/* .MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
} */

/* .MuiInputAdornment-positionStart {
  margin-right: 0px !important;
} */
/* .MuiIconButton-root {
  padding: 9px !important;
} */
.error-text {
  color: #f44336 !important;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
/* .MuiOutlinedInput-root {
  display: flex !important;
  flex-wrap: wrap !important;
} */
.calories-input .form-control {
  padding: 27px 14px !important;
  font-size: 1rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 5rem !important;
}
.calories-input .form-control:focus {
  border: 2px solid #3f51b5 !important;
  box-shadow: none !important;
}
.InActive {
  color: #ff0000 !important;
}
.active {
  columns: #000000 !important;
}
.btn-edit {
  background-color: #008cff;
  color: #fff;
  border: 1px solid #008cff;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: none;
  min-width: 50px;
}
.btn-deactivate {
  background-color: #ff007f;
  color: #fff;
  border: 1px solid #ff007f;
  font-weight: bold;
  border-radius: 4px;
  min-width: 100px;
}
.btn-deactivate:hover {
  color: #fff !important;
  font-weight: bold;
}
.btn-activate {
  background-color: #0058b2;
  color: #fff;
  border: 1px solid #0058b2;
  border-radius: 4px;
  font-weight: bold;
  min-width: 100px;
}
.btn-activate.hover {
  color: #fff !important;
  font-weight: bold;
}
.action-colors {
  color: #008cff;
}
.min-width-tbl {
  min-width: 22rem;
}
.min-width-action-tbl {
  min-width: 15rem;
}
.deactivate-img {
  filter: grayscale(100%);
}
/* .MuiIconButton-root {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
} */
/* .MuiOutlinedInput-inputAdornedEnd {
  padding-right: 34px !important;
} */
/* .MuiFormHelperText-root.Mui-error {
  background: #f5f5f5 !important;
  margin: 0 !important;
} */
.Guide-Tips-input .form-control {
  font-size: 1rem !important;
  height: 55px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 5rem !important;
}
.Guide-Tips-input .form-control:focus {
  border: 2px solid #3f51b5 !important;
  box-shadow: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.reactDrag {
  cursor: move;
}
.expandfullScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 2000;
  overflow: auto;
  display: flex;
  flex-direction: row;
}
.iconExpanDown
{
  transform: rotate(182deg);
}
.table-box
{
  border : 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 7px;
  box-shadow:2px 3px #e6e6e6;
}
.borderBottomBox
{
  /* border-bottom:2px solid #e6e6e6; */
  border : 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 7px;
  box-shadow:2px 3px #e6e6e6;
}